export default {
  home: {
    hello: "¡Hola,",
    description: "Este es tu panel de control de hoy.",
    advice: {
      name: "Consejo nº1",
      title: "Guía práctica para la sostenibilidad financiera de las ONG",
      description: "La primera parte del texto es una conceptualización.",
      more: "Ver más"
    },
    wallet: {
      currency: {
        euro: "€"
      },
      available: "disponibles"
    },
    tools: {
      title: "Tus herramientas",
      newProject: "Crear proyecto",
      newEvent: "Crear evento / curso",
      newProduct: "Crear producto",
      manageDonations: "Gestionar donaciones",
      consultant: "Contactar asesor",
      withdraw: "Retirar dinero"
    }
  }
};
