
  import { Component, Prop, Vue, Emit } from "vue-property-decorator";

  @Component
  export default class NavIcon extends Vue {
    @Prop({ default: "div" })
    tag!: string;

    @Prop({ default: false })
    active!: boolean;

    @Emit("click")
    onClick(): void {
      return;
    }

    get cssClasses(): any {
      return {
        "lz-nav-icon--active": this.active
      };
    }
  }
