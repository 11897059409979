import { render, staticRenderFns } from "./NavIcon.vue?vue&type=template&id=c79a5ae4&scoped=true&lang=pug&"
import script from "./NavIcon.vue?vue&type=script&lang=ts&"
export * from "./NavIcon.vue?vue&type=script&lang=ts&"
import style0 from "./NavIcon.vue?vue&type=style&index=0&id=c79a5ae4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c79a5ae4",
  null
  
)

export default component.exports