export default {
  recover: {
    title: "Recupera tu contraseña",
    resetTitle: "Restablece tu contraseña",
    resetDetails: "Establece tu nueva contraseña",
    completedTitle: "Contraseña actualizada correctamente",
    completedDetails: "Ya puedes ir al login y acceder a tu cuenta",
    details:
      "Introduce tu email y te enviaremos un correo para recuperar tu contraseña",
    completed:
      "Te hemos enviado un email a tu correo electrónico para completar el proceso.",
    form: {
      email: "Email",
      password: "Contraseña",
      repeatPassword: "Repetir contraseña"
    },
    errors: {
      userNotFound: "No se ha encontrado la dirección de correo."
    }
  },
  signin: {
    access: "Accede",
    accessMicro: "¡Accede a tu micro y disfruta!",
    haveAccount: "¿Ya tienes cuenta?",
    title: "Accede a tu micro",
    rememberPassword: "¿Has olvidado tu contraseña?",
    form: {
      email: "Email",
      password: "Contraseña"
    },
    errors: {
      wrongCredentials: "Los datos de acceso no son correctos."
    }
  },
  signup: {
    title: "¿Aún no tienes cuenta?",
    subtitle: "¡ Crea tu Micro y disfruta !",
    modal: {
      message:
        "Para poder finalizar con el proceso de registro es necesario que introduzca el código de verificación que hemos enviado por SMS:",
      verifCode: "Código de verificación"
    },
    createMicro: "Crea tu micro",
    form: {
      firstName: "Nombre",
      lastName: "Apellidos",
      mobilePhone: "Teléfono móvil",
      phoneHelp: "Incluya el código de país +34. Ej: +34612345678",
      name: "Nombre de la organización",
      type: "Tipo de organización",
      organizations: {
        ong: "ONG",
        limited: "PYME & Autónomos",
        research: "Grupos de investigación",
        art: "Grupos artísticos y culturales",
        other: "Otros"
      },
      password: "Contraseña",
      repeatPW: "Repetir contraseña",
      email: "Email",
      acceptTerms: "Acepto los términos y condiciones",
      terms: "términos y condiciones",
      newsletter: "Quiero subscribirme a la newsletter",
      cifnif: "CIF / NIF"
    },
    createBtn: "Crear plataforma",
    notifications: {
      required: "Teléfono es requerido",
      matches: "Formato de teléfono inválido",
      success: "Usuario validado con éxito",
      failCode: "Código no válido",
      error: "Ha ocurrido un error"
    }
  },
  onboarding: {
    steps: {
      stepWelcome: "Bienvenido",
      stepTools: "Elige las herramientas",
      stepBrand: "Define tu identidad",
      stepSubscription: "Elige tu plan"
    },
    stepWelcome: {
      step1: "Paso 1",
      title: "MICRO-PLATAFORMAS",
      subtitle:
        "Bienvenid@ a tu aventura digital, en pocos minutos tendrás configurada tu plataforma"
    },
    stepTools: {
      title: "Tus herramientas",
      subtitle:
        "Selecciona las herramientas con las que quieres crear tu portal",
      tools: {
        projects: "Proyectos",
        donations: "Donaciones",
        partners: "Socios",
        market: "Tienda online",
        events: "Eventos",
        courses: "Cursos",
        volunteers: "Voluntariado"
      },
      notifications: "Herramientas añadidas con éxito"
    },
    stepBrand: {
      title: "Tu estilo",
      subtitle: "Elige tus señas de identidad",
      form: {
        logo: {
          label: "Sube tu logo",
          help: "Añade una imagen en png o jpg."
        },
        primaryColour: "Elige color primario",
        secondaryColour: "Elige color secundario"
      },
      successIdentity: "Identidad añadida con éxito"
    }
  }
};
