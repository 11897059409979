
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component
  export default class FormulateInputToggle extends Vue {
    @Prop({ required: true })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected readonly context!: any;

    get attributes() {
      return this.context.attributes || {};
    }

    @Prop({ required: false })
    protected readonly underLabel?: string;
  }
