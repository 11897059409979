
  import { Component, Prop, Vue } from "vue-property-decorator";
  import FormulateInputMixin from "@braid/vue-formulate";

  @Component({
    mixins: [FormulateInputMixin]
  })
  export default class FormulateInputToggle extends Vue {
    @Prop({ required: true })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected readonly context!: any;

    get attributes() {
      return this.context.attributes || {};
    }
  }
