export default {
  public: {
    title: "Web pública",
    subtitle: "Configura aquí tu web pública",
    generalForm: {
      title: "General",
      publicate: "Publicar web",
      status: {
        label: "Estado de la publicación",
        options: {
          enabled: "Activado",
          disabled: "Desactivado"
        }
      },
      seeWeb: "Ver web",
      url: "URL de tu portal",
      powered: {
        title: 'Quitar "Powered by Lázzaro" del pie de página',
        under: "Se mostrará el mensaje al final de la web",
        options: {
          enabled: "Activado",
          disabled: "Desactivado"
        }
      }
    },
    logosForm: {
      title: "Logos",
      subtitle:
        "Hemos dejado este espacio para que añadas logos de cosas chulas",
      logo: {
        label: "Logotipo",
        help: "180x60, máx 25MB",
        limit: "No puedes agregar más de 10 logos",
        delete: "Logotipo eliminado correctamente",
        error: "Error al eliminar el logotipo"
      }
    },
    chooseTemplate: {
      title: "Plantilla",
      template1: "Elegir plantilla 1",
      template2: "Elegir plantilla 2",
      template3: "Elegir plantilla 3",
      modalText: "elige una plantilla",
      templateChangeModal: {
        title: "La plantilla está siendo cambiada.",
        subtitle:
          "¡Genial, vas a ver la nueva plantilla! esto puede tardar unos minutos. Es el momento perfecto para preparar el café."
      }
    },

    googleAnalytics: {
      title: "ID Google Analytics"
    },

    websiteStatus: {
      publishWebsite: {
        title: "Estamos publicando tu web, esto puede tardar hasta 5 minutos.",
        subtitle:
          'Recuerda que puedes volver a ocultarla otra vez cuando quieras pulsando el mismo botón y dándole a "guardar"'
      },

      unpublishWebsite: {
        title: "Estamos ocultando tu web, esto puede tardar hasta 5 minutos.",
        subtitle:
          "Recuerda que puedes volver a publicarla cuando quieras clicando el mismo botón y después dándole al botón “guardar”."
      }
    },
    brandForm: {
      title: "Marca",
      logo: {
        label: "Logotipo",
        help: "180x60, máx 25MB"
      },
      mainImg: {
        label: "Imagen principal",
        help: "180x60, máx 25MB"
      },
      orgName: "Nombre de la organización",
      colour: {
        first: "Color destacado 1",
        second: "Color destacado 2",
        footer: "Color del footer"
      }
    },
    contactForm: {
      title: "Contacto",
      phone: "Teléfono",
      location: "Ubicación",
      backgroundColour: "Color de fondo"
    },
    contentForm: {
      title: "Contenido",
      subtitle:
        "Selecciona qué secciones quieres que aparezcan en tu web pública.",
      sectionStatus: {
        title: "Estado de la sección",
        options: {
          enabled: "Activado",
          disabled: "Desactivado"
        }
      },
      projects: "Proyectos",
      donations: "Donaciones",
      partners: "Socios",
      market: "Tienda online",
      courses: "Cursos",
      impact: "Datos de impacto",
      events: "Eventos",
      volunteers: "Voluntarios",
      designButton:"Diseño"
    },
    socialForm: {
      title: "Redes sociales",
      facebook: "Link de Facebook",
      linkedin: "Link de LinkedIn",
      instagram: "Link de Instagram",
      twitter: "Link de Twitter",
      web: "Link web"
    },
    termsForm: {
      title: "Términos y condiciones, aviso legal y política de privacidad",
      add: "Seleccionar archivos",
      terms:
        "Sube tus términos y condiciones, aviso legal y política de privacidad para que tus usuarios las acepten",
      transparencyDescription: "Descripción de transparencia",
      accountability: "Transparencia y rendición de cuentas"
    },
    teamForm: {
      title: "Equipo",
      subtitle:
        "Configura y crea uno a uno cada miembro de tu equipo. Puedes verlos todos en el listado de abajo.",
      photo: {
        label: "Foto de perfil",
        help: "180x60, máx 25MB"
      },
      name: "Nombre y apellidos",
      position: "Cargo",
      linkedin: "Link a LinkedIn",
      newMember: "Añadir miembro",
      table: "Tu equipo",
      delete: ""
    },
    descriptionForm: {
      title: "Descripción",
      subtitle:
        "Acompaña la web de una descripción de tu organización, de tu visión, de tu misión y de tus valores.",
      motto: {
        label: "Lema de la organización",
        help: "Máx. 40 caracteres."
      },
      description: "Descripción corta de la organización",
      titleDescription: {
        label: "Título de la descripción",
        help: "Máx. 40 caracteres."
      },
      secondaryImg: {
        label: "Imagen secundaria",
        help: "180x60, máx 25MB"
      },
      largeDescription: "Descripción larga"
    },
    impactForm: {
      title: "Datos de impacto",
      subtitle: "Modifica los datos de impacto visibles en la web.",
      text: {
        one: "Texto impacto 1",
        two: "Texto impacto 2",
        three: "Texto impacto 3",
        four: "Texto impacto 4"
      },
      amount: {
        one: "Cantidad impacto 1",
        two: "Cantidad impacto 2",
        three: "Cantidad impacto 3",
        four: "Cantidad impacto 4"
      }
    },
    notify: {
      success: "Cambios guardados con éxito",
      error: "Ha ocurrido un error al guardar"
    },
    modalDesign:{
      title:"Diseño",
      projects:{
        title:"Proyectos",
        backgroundColor: "Color de fondo"
      },
      members:{
        title:"Miembros",
        backgroundColor: "Color de fondo"
      },
      courses:{
        title:"Cursos",
        backgroundColor: "Color de fondo"
      },
      hero:{
        title:"Hero",
        backgroundColor: "Color de fondo"
      },
      shop:{
        title:"Tienda",
        backgroundColor: "Color de fondo"
      },
      aboutUs:{
        title:"Sobre nosotros",
        backgroundColor: "Color de fondo"
      },
      events:{
        title:"Eventos",
        backgroundColor: "Color de fondo"
      },
      volunteers:{
        title:"Voluntarios",
        backgroundColor: "Color de fondo"
      },
      logo:{
        title:"Logo",
        backgroundColor: "Color de fondo"
      },
    }
  }
};
