export default {
  read: {
    title: "Datos",
    subtitle: "Datos y estadísticas",
    income: "Ingresos",
    totalIncome: "Ingresos totales",
    incomeOverTime: "Ingresos en el tiempo",
    createdProjects: "proyectos creados",
    newEvents: "eventos totales",
    newCourses: "cursos totales",
    totalPartners: "socios totales",
    newDonations: "donaciones totales",
    collected: "Recaudado este mes",
    currentCollected: "recaudados - 5% de Lazzaro",
    withdraw: "Retirar dinero recaudado",
    totalDonors: "Nº de donantes",
    labels: {
      projects: "Proyectos",
      events: "Eventos",
      courses: "Cursos",
      partners: "Socios",
      donations: "Donaciones"
    }
  }
};
