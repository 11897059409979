export default {
  home: {
    hello: "Hello,",
    description: "Control panel",
    advice: {
      name: "Tip 1",
      title: "Practical guide to financial sustainability in NGOs",
      description: "The first part of the text is a conceptualisation.",
      more: "View more"
    },
    wallet: {
      currency: {
        euro: "€"
      },
      available: "available"
    },
    tools: {
      title: "Toolbar",
      newProject: "Create new project",
      newEvent: "Create new event / course",
      newProduct: "Create new product",
      manageDonations: "Manage donations",
      consultant: "Contact consultant",
      withdraw: "Withdraw money"
    }
  }
};
