export default {
  actions: {
    cancel: "Cancel",
    withdraw: "Withdraw",
    delete: "Delete",
    askConfirm: "Do you want to confirm?",
    confirm: "Confirm",
    downloadData: "Download data",
    changePlan: "Change plan",
    send: "Send",
    enter: "Login",
    back: "Back",
    next: "Next",
    create: "Create",
    save: "Save",
    deleteAccount: "Delete account",
    search: "Search",
    yes: "Yes",
    no: "No",
    active: "Active",
    close: "Close",
    alreadyConnected: "Already connected",
    connectToStripe: "Connect to Stripe",
    goToLogin: "go to login"
  },
  notifications: {
    changeSuccess: "Successfully saved changes",
    upgradeToPremium: "You must have a premium Subscription to use this feature"
  },
  error: {
    generic: "An error has occurred"
  },
  nav: {
    Web: "Web",
    Proyectos: "Projects",
    Tienda: "Shop",
    Calendario: "Calendar",
    Cuentas: "Accounts",
    Socios: "Partners",
    Organización: "Organization",
    Asesor: "consultant",
    NFTs: "NFTs",
    Datos: "Data",
    Mejora: "Improve"
  },
  logout: "Logout"
};
