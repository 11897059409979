
  import { Component, Vue } from "vue-property-decorator";
  import { namespace } from "vuex-class";
  import { getAuth, hasAuth } from "./api/core/auth";
  import LzLoader from "@/modules/loader/components/Loader.vue";
  import http from "@/api/core/http";
  import { apiOngs } from "./modules/user/api";
  const auth = namespace("auth");

  @Component({
    components: { LzLoader }
  })
  export default class App extends Vue {
    @auth.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateData!: (payload: any) => void;

    @auth.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateFeatures!: (payload: any) => void;

    @auth.State("id")
    public ongId!: string;

    @auth.Mutation("setBlockedBySubscriptionPlan")
    public setBlockedBySubscriptionPlan!: (payload: boolean) => void;

    async mounted() {
      if (hasAuth()) {
        this.updateData(getAuth());
        await this.updateFeatures(this.ongId);

        await this.checkBlockedStatus(this.ongId).catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        });
      }

      http.client.interceptors.request.use(
        config => {
          this.setLoading(true);
          return config;
        },
        error => {
          this.setLoading(false);
          return Promise.reject(error);
        }
      );

      http.client.interceptors.response.use(
        config => {
          this.setLoading(false);
          return config;
        },
        error => {
          this.setLoading(false);
          return Promise.reject(error);
        }
      );
    }

    async checkBlockedStatus(ongId: string) {
      const {
        data: { PlatformSubscription } = {}
      } = await apiOngs.getOrganizationPlan(ongId);

      if (!PlatformSubscription) {
        this.setBlockedBySubscriptionPlan(true);

        if (this.$route.name !== "organizationRead") {
          this.$router.push({ name: "organizationRead" });
        }
      }
    }
  }
