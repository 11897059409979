export default {
  recover: {
    title: "Recover your password",
    resetTitle: "reset your password",
    resetDetails: "Set your new password",
    completedTitle: "Password updated successfully",
    completedDetails: "You can now go to the login and access your account",
    details:
      "Enter your email and we will send you an email to recover your password",
    completed:
      "We have sent you an email to your email to complete the process.",
    form: {
      email: "Email",
      password: "Password",
      repeatPassword: "Repeat password"
    },
    errors: {
      wrongCredentials: "The access data is not correct."
    }
  },
  signin: {
    access: "Login",
    accessMicro: "Access your Micro and enjoy!",
    haveAccount: "Already have an account?",
    title: "Access your Micro",
    rememberPassword: "Forgot password",
    form: {
      email: "Email address",
      password: "Password"
    },
    errors: {
      wrongCredentials: "Wrong email or password!"
    }
  },
  signup: {
    title: "Don't have an account yet?",
    subtitle: "Create your Micro and enjoy !",
    modal: {
      message:
        "In order to finish the registration process, it is necessary to enter the verification code that we have sent by SMS:",
      verifCode: "Verification code"
    },
    createMicro: "Create your Micro",
    form: {
      firstName: "Name",
      lastName: "Surname",
      mobilePhone: "Phone number",
      phoneHelp: "Include the country code +34. Ex: +34612345678",
      name: "Company name",
      type: "Type of organization",
      organizations: {
        ong: "ONG",
        limited: "SMEs & Self-Employed",
        research: "Research groups",
        art: "Artistic and cultural groups",
        other: "Other"
      },
      password: "Password",
      repeatPW: "Repeat password",
      email: "Email address",
      terms: "I accept the terms and conditions",
      acceptTerms: "Accept terms and conditions",
      newsletter: "I want to be subscribed to the newsletter",
      cifnif: "CIF / NIF"
    },
    createBtn: "Create platform",
    notifications: {
      required: "Phone no is required",
      matches: "Invalid phone format",
      success: "User successfully validated",
      failCode: "Invalid code"
    }
  },
  onboarding: {
    steps: {
      stepWelcome: "Welcome",
      stepTools: "Choose the tools",
      stepBrand: "Define your identity",
      stepSubscription: "Choose your plan"
    },
    stepWelcome: {
      step1: "Step 1",
      title: "MICRO-PLATFORMS",
      subtitle:
        "Welcome to your digital adventure, in a few minutes you will have your platform configured."
    },
    stepTools: {
      title: "Your tools",
      subtitle: "Select the tools you want to create your portal with",
      tools: {
        projects: "Projects",
        donations: "Donations",
        partners: "Members",
        market: "Shop",
        events: "Events",
        courses: "Courses",
        volunteers: "Volunteering"
      },
      notifications: "Tools successfully added"
    },
    stepBrand: {
      title: "Your style",
      subtitle: "Choose your identity signs",
      form: {
        logo: {
          label: "Upload your logo",
          help: "Add a png or jpg image."
        },
        primaryColour: "Choose primary colour",
        secondaryColour: "Choose secondary colour"
      },
      successIdentity: "Identity successfully added"
    }
  }
};
