
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "./Button.vue";

  @Component({
    components: { LzButton }
  })
  export default class LevelUpButton extends Vue {
    redirectPlans(): void {
      if (this.$route.name !== "organizationRead") {
        this.$router.push({
          name: "organizationRead",
          params: { step: "subscription" }
        });
      }
    }
  }
