
  import { Component, Vue } from "vue-property-decorator";
  import LzNavIcon from "./components/NavIcon.vue";
  import { DeviceLaptopIcon } from "vue-tabler-icons";
  import { LZ_MODULES } from "@/config/modules";
  import { namespace } from "vuex-class";

  const auth = namespace("auth");

  type Features = {
    causes: boolean;
    courses: boolean;
    donations: boolean;
    events: boolean;
    impact: boolean;
    market: boolean;
    NFTs: boolean;
    partners: boolean;
    volunteers: boolean;
  };

  @Component({
    components: { LzNavIcon, DeviceLaptopIcon }
  })
  export default class Main extends Vue {
    @auth.State("features")
    public features!: Features;

    get lzModules() {
      return LZ_MODULES;
    }

    get visibleLzModules() {
      return Object.keys(this.lzModules).reduce((lzModules, moduleKey) => {
        const features = this.lzModules[moduleKey].features;
        if (!features || features.some((key: string) => this.features[key])) {
          lzModules[moduleKey] = this.lzModules[moduleKey];
        }
        return lzModules;
      }, {} as typeof LZ_MODULES);
    }
  }
