
  import { Component, Vue } from "vue-property-decorator";

  @Component
  export default class Loader extends Vue {
    beforeMount() {
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    }

    beforeDestroy() {
      document.getElementsByTagName("body")[0].style.overflowY = "auto";
    }
  }
