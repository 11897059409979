import Vue from "vue";
import Vuex from "vuex";
import keysToCamel from "@/utils/keysToCamel";
import store from "@/store";

Vue.use(Vuex);

import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
  config
} from "vuex-module-decorators";
import { apiOngs } from "@/modules/web/api";
import { getAuth } from "@/api/core/auth";

config.rawError = true;
@Module({ namespaced: true, name: "auth", dynamic: true, store })
class Auth extends VuexModule {
  // state
  active = null;
  address = null;
  ambit = null;
  createdAt = null;
  description = null;
  dltAddress = null;
  email = null;
  homeUrl = null;
  id: string | null = null;
  imgUrl = null;
  ioCashUserId = null;
  mobilePhone = null;
  name = null;
  nif = null;
  password = null;
  pk = null;
  type = null;
  updatedAt = null;
  features = {
    causes: false,
    courses: false,
    donations: false,
    events: false,
    impact: false,
    market: false,
    partners: false,
    volunteers: false
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ongConfiguration: any = {
    currency: "",
    currency_symbol: ""
  };
  paypalClientId: string | null = null;
  paypalClientSecret: string | null = null;
  stripe_id: string | null = null;
  mollie_org_api_key: string | null = null;
  crypto_wallet_address: string | null = null;

  // subscription plan
  blockedBySubscriptionPlan = false;

  // mutations
  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setData(payload: any): void {
    Object.keys(payload).forEach(v => {
      this[v] = payload[v];
    });
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setFeatures(payload: any): void {
    Object.keys(payload).forEach(v => {
      this.features[v] = payload[v];
    });
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setPaypalConfig(payload: any) {
    this.paypalClientId = payload.id;
    this.paypalClientSecret = payload.secret;

    // updates auth in localStorage
    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...getAuth(),
        paypalClientId: payload.id,
        paypalClientSecret: payload.secret
      })
    );
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setOngConfig(payload: any): void {
    Object.keys(payload).forEach(v => {
      this.ongConfiguration[v] = payload[v];
    });
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setBlockedBySubscriptionPlan(payload: boolean): void {
    this.blockedBySubscriptionPlan = payload;
  }

  // actions
  @Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateData(payload: any): void {
    this.context.commit("setData", keysToCamel(payload));
  }

  @Action
  public async updateFeatures(): Promise<void> {
    if (this.id) {
      return await apiOngs.getAllPlatformConfig(this.id).then(
        ({
          data: {
            features: {
              causes,
              courses,
              donations,
              events,
              impact,
              market,
              partners,
              volunteers
            },
            platformConfig: {
              currency,
              currency_symbol,
              language,
              url,
              payment_method,
              active,
              powered_by_lazzaro,
              template_id
            }
          }
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          this.context.commit("setFeatures", {
            causes,
            courses,
            donations,
            events,
            impact,
            market,
            partners,
            volunteers
          });
          this.context.commit("setOngConfig", {
            currency,
            currency_symbol,
            language,
            url,
            payment_method,
            active,
            powered_by_lazzaro,
            template_id
          });
        }
      );
    }
  }
}

export default getModule(Auth);
